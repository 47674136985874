<template>
    <div id="data-list-list-view" class="data-list-container" v-if="advice">
        <AdviceTable
            :data="advice"
            :itemsPerPage="itemsPerPage"
            :dataSelected="selected"
            @itemsPerPage="setItemsPerPage"
            @search="search"
            @limit="setLimit"
            @selected="selected = $event"
            @selectedEdit="selectedEdit = $event"
            @selectedDelete="selectedDelete"
            @create="show.createModal = true"
            @contact="multipleContact"
            @followUp="multipleFollowUp"
            @callNotAnswered="multipleCallNotAnswered"
            @endSold="multipleSold"
            @endNotTaken="multipleNotTaken"
            @endNoContact="multipleNoContact"
        ></AdviceTable>
        <vs-pagination
            :total="advice.number_of_pages"
            v-model="pageNumber"
        ></vs-pagination>
        <EditAdviceModal
            :show="selectedEdit"
            :data="selectedEdit"
            @close="selectedEdit = null"
            @newData="replaceData"
        ></EditAdviceModal>
        <CreateAdviceModal
            :show="show.createModal"
            @close="show.createModal = false"
            @changed="getPage()"
        ></CreateAdviceModal>
        <!-- delete modal -->
        <vs-popup
            :active.sync="show.deleteModal"
            @close="show.deleteModal = false"
            class="holamundo"
            :title="'Delete Advice Request'"
        >
            <vs-button color="secondary" class="mr-3" v-on:click="deleteAdvice"
                >Confirm</vs-button
            >
            <vs-button v-on:click="show.deleteModal = false" type="flat"
                >Cancel</vs-button
            >
        </vs-popup>
    </div>
</template>

<script>
import AdviceTable from './Table'
import EditAdviceModal from './Modals/EditModal'
import CreateAdviceModal from './Modals/CreateModal'

import { http } from '@/services'

export default {
    name: 'Advice',
    components: {
        AdviceTable,
        EditAdviceModal,
        CreateAdviceModal,
    },
    created() {
        console.log(this.$route)
        if (this.$route.params.query) {
            console.log('do a search')
            this.getPage(this.$route.params.query)
            return true
        }
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    beforeRouteUpdate(to, from, next) {
        next()
        if (this.$route.params.page)
            this.pageNumber = Number(this.$route.params.page)
        this.getPage()
    },
    watch: {
        pageNumber(newValue, oldValue) {
            if (Number(newValue) !== Number(oldValue))
                this.$router.push({
                    name: 'advice',
                    params: { page: Number(newValue) },
                })
        },
    },
    computed: {
        adviceData() {
            if (this.advice && this.advice.data) return this.advice.data
            return []
        },
    },
    data() {
        return {
            advice: null,
            pageNumber: 1,
            itemsPerPage: 10,
            queriedItems: 20,
            limit: 'attention',
            selectedEdit: null,
            show: {
                createModal: false,
                deleteModal: false,
            },
            selected: {
                delete: null,
            },
        }
    },
    methods: {
        setItemsPerPage(value) {
            this.itemsPerPage = value
            this.getPage()
        },
        setPageNumber(value) {
            this.pageNumber = value
            this.getPage()
        },
        getPage(searchQuery = null) {
            http.get('advice_list', {
                params: {
                    limit: this.limit,
                    per_page: this.itemsPerPage,
                    page: this.pageNumber,
                    query: searchQuery,
                },
            })
                .then((response) => {
                    this.advice = response.data
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        search(value) {
            this.getPage(value)
        },
        setLimit(value) {
            this.limit = value
            this.getPage()
        },
        replaceData() {
            this.advice
            this.getPage()
        },
        selectedDelete(value) {
            this.selected.delete = value
            this.show.deleteModal = true
        },
        deleteAdvice() {
            http.delete('advice_detail', {
                params: { id: this.selected.delete.id },
            })
                .then((response) => {
                    this.selected.delete = null
                    this.show.deleteModal = false
                    this.getPage()
                    this.$vs.notify({
                        title: 'Success',
                        text: 'Advice deleted',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    this.$vs.notify({
                        title: 'Success',
                        text: error.response.data,
                        color: 'success',
                    })
                })
        },
        multipleContact() {
            this.multipleSet('advice_multiple_contact')
        },
        multipleFollowUp() {
            this.multipleSet('advice_multiple_follow_up')
        },
        multipleCallNotAnswered() {
          this.multipleSet('advice_multiple_call_not_answered')
        },
        multipleSold() {
            this.multipleSet('advice_multiple_sold')
        },
        multipleNotTaken() {
            this.multipleSet('advice_multiple_not_taken')
        },
        multipleNoContact() {
            this.multipleSet('advice_multiple_no_contact')
        },
        multipleSet(url) {
            var selectedArray
            if (Array.isArray(this.selected))
                selectedArray = this.selected.map((data) => data.id)
            else selectedArray = [this.selected.id]

            http.post(url, selectedArray)
                .then((response) => {
                    this.getPage()
                    this.selected = []
                    this.show.deleteModal = false
                    this.$vs.notify({
                        text: 'Advice table updated',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'danger',
                    })
                })
        },
    },
}
</script>

<style lang="scss">
#data-list-list-view {
    .vs-con-table {
        /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
        @media (max-width: 689px) {
            .vs-table--search {
                margin-left: 0;
                max-width: unset;
                width: 100%;

                .vs-table--search-input {
                    width: 100%;
                }
            }
        }

        @media (max-width: 461px) {
            .items-per-page-handler {
                display: none;
            }
        }

        @media (max-width: 341px) {
            .data-list-btn-container {
                width: 100%;

                .dd-actions,
                .btn-add-new {
                    width: 100%;
                    margin-right: 0 !important;
                }
            }
        }

        .product-name {
            max-width: 23rem;
        }

        .vs-table--header {
            display: flex;
            flex-wrap: wrap;
            margin-left: 1.5rem;
            margin-right: 1.5rem;
            > span {
                display: flex;
                flex-grow: 1;
            }

            .vs-table--search {
                padding-top: 0;

                .vs-table--search-input {
                    padding: 0.9rem 2.5rem;
                    font-size: 1rem;

                    & + i {
                        left: 1rem;
                    }

                    &:focus + i {
                        left: 1rem;
                    }
                }
            }
        }

        .vs-table {
            border-collapse: separate;
            border-spacing: 0 1.3rem;
            padding: 0 1rem;

            tr {
                box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
                td {
                    padding: 20px;
                    &:first-child {
                        border-top-left-radius: 0.5rem;
                        border-bottom-left-radius: 0.5rem;
                    }
                    &:last-child {
                        border-top-right-radius: 0.5rem;
                        border-bottom-right-radius: 0.5rem;
                    }
                }
                td.td-check {
                    padding: 20px !important;
                }
            }
        }

        .vs-table--thead {
            th {
                padding-top: 0;
                padding-bottom: 0;

                .vs-table-text {
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }
            th.td-check {
                padding: 0 15px !important;
            }
            tr {
                background: none;
                box-shadow: none;
            }
        }

        .vs-table--pagination {
            justify-content: center;
        }
    }
}
</style>
