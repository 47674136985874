<template>
  <vs-table
      ref="table"
      v-model="selected"
      search
      :sst="true"
      :data="wills"
      :multiple="selectMultiple"
      @search="search"
  >
    <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-end"
    >
      <p v-show="searchQuery" class="mr-4 mb-4">
        Search results for '{{ searchQuery }}'
      </p>
      <!-- Actions -->
      <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
      >
        <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
        >
          <span class="mr-2"> Action </span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
        </div>
        <vs-dropdown-menu>
          <vs-dropdown-item @click="toggleSelect">
                        <span>{{
                            selectMultiple ? 'Single Select' : 'Multiple Select'
                          }}</span>
          </vs-dropdown-item>
          <vs-dropdown-item divider @click="$emit('create')">
            <span>Create</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              divider
              :disabled="selected.length < 1"
              v-if="selectMultiple"
              @click="$emit('contact')"
          >
            <span>To Contact</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              v-if="selectMultiple"
              :disabled="selected.length < 1"
              @click="$emit('followUp')"
          >
            <span>To Follow Up</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              v-if="selectMultiple"
              :disabled="selected.length < 1"
              @click="$emit('callNotAnswered')"
          >
            <span>To Call not Answered</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              v-if="selectMultiple"
              :disabled="selected.length < 1"
              @click="$emit('endSold')"
          >
            <span>End - Sold</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              v-if="selectMultiple"
              :disabled="selected.length < 1"
              @click="$emit('endNotTaken')"
          >
            <span>End - Lost Not Taken</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              v-if="selectMultiple"
              :disabled="selected.length < 1"
              @click="$emit('endNoContact')"
          >
            <span>End - Lost No Contact</span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
      <!-- STATUS -->
      <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
      >
        <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
        >
          <span class="mr-2"> {{ status }} </span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
        </div>
        <vs-dropdown-menu>
          <vs-dropdown-item
              @click="setLimit('All - Unresolved', 'attention')"
          >
            <span>All - Unresolved </span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="setLimit('All', 'all')">
            <span>All</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              divider
              @click="setLimit('To Contact', 'to_contact')"
          >
            <span>To Contact</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              @click="setLimit('To Follow Up', 'to_follow_up')"
          >
            <span>To Follow Up</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              @click="setLimit('Call Not Answered', 'call_not_answered')"
          >
            <span>Call Not Answered</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              divider
              @click="setLimit('End - Lost Not Taken', 'end_lost')"
          >
            <span>End - Lost Not Taken</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              @click="
                            setLimit('End - Lost No Contact', 'end_no_contact')
                        "
          >
            <span>End - Lost No Contact</span>
          </vs-dropdown-item>
          <vs-dropdown-item
              @click="setLimit('End - Sold', 'end_sold')"
          >
            <span>End - Sold</span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>

      <!-- ITEMS PER PAGE -->
      <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
      >
        <div
            class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
        >
          <span class="mr-2"> {{ itemsPerPage }} </span>
          <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
        </div>
        <vs-dropdown-menu>
          <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
            <span>5</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
            <span>10</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
            <span>20</span>
          </vs-dropdown-item>
          <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
            <span>50</span>
          </vs-dropdown-item>
        </vs-dropdown-menu>
      </vs-dropdown>
    </div>

    <template slot="thead">
      <vs-th>Name</vs-th>
      <vs-th>Request</vs-th>
      <vs-th>Status</vs-th>
      <vs-th>Request Date</vs-th>
      <vs-th>Appointment</vs-th>
      <vs-th>Introducer</vs-th>
      <vs-th>Link</vs-th>

    </template>

    <template slot-scope="{ data }">
      <tbody>
      <vs-tr
          :data="advice"
          :key="index"
          v-for="(advice, index) in data"
          :class="{ 'bg-grey': !advice.user }"

      >
        <vs-td>
                        <span v-if="advice.user" @click="gotoUser(advice)">{{
                            advice.user.details.full_name
                          }} <vs-chip>User</vs-chip></span>
          <span v-else>{{ advice.client_name }}<vs-chip>Lead</vs-chip></span>
        </vs-td>

        <vs-td>
          <vs-chip v-if="advice.power_of_attorney" class="mr-3">
            Power of Attorney
          </vs-chip>
          <vs-chip v-if="advice.protect_home" class="mr-3">
            Home Protection
          </vs-chip>
          <vs-chip v-if="advice.life_insurance" class="mr-3">
            Life Insurance
          </vs-chip>
          <vs-chip
              v-if="advice.life_insurance_review"
              class="mr-3"
          >
            Life Insurance Review
          </vs-chip>
          <vs-chip v-if="advice.inheritance_tax" class="mr-3">
            Inheritance Tax
          </vs-chip>
          <vs-chip v-if="advice.funeral_plan" class="mr-3">
            Funeral Plan
          </vs-chip>
          <vs-chip v-if="advice.assets_abroad" class="mr-3">
            Assets Abroad
          </vs-chip>
          <vs-chip v-if="advice.business" class="mr-3">
            Business
          </vs-chip>
          <vs-chip v-if="advice.disabled" class="mr-3">
            Disabled
          </vs-chip>
          <vs-chip v-if="advice.children" class="mr-3"
          >Children
          </vs-chip
          >
          <vs-chip v-if="advice.complex_will" class="mr-3">
            Complex Will
          </vs-chip>
          <vs-chip
              v-if="advice.protective_property_trust"
              class="mr-3"
          >
            Protective Property Trust
          </vs-chip>
          <vs-chip v-if="advice.right_of_occupation" class="mr-3">
            Right Of Occupation
          </vs-chip>
          <vs-chip v-if="advice.life_interest_trust" class="mr-3">
            Life Interest Trust
          </vs-chip>
          <vs-chip v-if="advice.other_advice" class="mr-3">{{
              advice.other_advice
            }}
          </vs-chip>
          <vs-chip v-if="advice.scotland1" class="mr-3">
            Scotland Advice - Married with Children
          </vs-chip>
          <vs-chip v-if="advice.scotland2" class="mr-3">
            Scotland Advice - Married without Children
          </vs-chip>
          <vs-chip v-if="advice.scotland3" class="mr-3">
            Scotland Advice - Not Married with Children
          </vs-chip>
          <vs-chip v-if="advice.right_to_occupy1" class="mr-3">
            Right to Occupy - Leaving all residue to partner &
            has children
          </vs-chip>
          <vs-chip v-if="advice.right_to_occupy2" class="mr-3">
            Right to Occupy - Not leaving all residue to partner
          </vs-chip>
        </vs-td>
        <vs-td>
          <vs-chip>{{ statusLabel(advice) }}</vs-chip>
        </vs-td>

        <vs-td>
                        <span class="flex items-center px-2 py-1 rounded">
                            {{ formatDateAndTime(advice.date) }}
                        </span>
        </vs-td>
        <vs-td>
                        <span
                            v-if="advice.appointment"
                            class="flex items-center px-2 py-1 rounded"
                        >
                            {{
                            formatDateAndTime(advice.appointment.date_time)
                          }}
                        </span>
        </vs-td>

        <vs-td>
                        <span
                            v-if="advice.broker"
                            class="link"
                            v-on:click="
                                $router.push({
                                    name: 'broker',
                                    params: { pk: advice.broker.email },
                                })
                            "
                        >{{ advice.broker.company_name }}</span
                        >
          <span
              v-else-if="
                                advice.will_document &&
                                advice.will_document.coupon_used &&
                                advice.will_document.coupon_used.broker
                            "
          >{{
              advice.will_document.coupon_used.broker
                  .company_name
            }}</span
          >
        </vs-td>
        <vs-td>
          <vs-button
              v-on:click="gotoUserInNewTab(advice)"
              type="flat"
              size="small"
              icon-pack="feather"
              icon="icon-link"
              color="danger"
              class="mr-2"
          ></vs-button>
        </vs-td>

        <!--                <template-->
        <!--                    slot="expand"-->
        <!--                        class="expand-user"-->
        <!--                        v-if="!advice.user && !selectMultiple"-->
        <!--                >-->
        <!--                    <div class="con-expand-users w-full">-->
        <!--                        <div-->
        <!--                                class="con-btns-user flex items-center justify-between"-->
        <!--                        >-->
        <!--                            <div-->
        <!--                                    class="con-userx flex items-center justify-start"-->
        <!--                            >-->
        <!--                                    <span v-if="advice.broker"-->
        <!--                                    >Advice request received from-->
        <!--                                        {{ advice.broker.company_name }}</span-->
        <!--                                    >-->
        <!--                            </div>-->
        <!--                            <div class="flex">-->
        <!--                                <vs-button-->
        <!--                                        v-on:click="-->
        <!--                                            $emit('selectedEdit', advice)-->
        <!--                                        "-->
        <!--                                        type="flat"-->
        <!--                                        size="small"-->
        <!--                                        icon-pack="feather"-->
        <!--                                        icon="icon-edit-2"-->
        <!--                                        class="mr-2"-->
        <!--                                ></vs-button>-->
        <!--                                <vs-button-->
        <!--                                        v-on:click="-->
        <!--                                            $emit('selectedDelete', advice)-->
        <!--                                        "-->
        <!--                                        type="flat"-->
        <!--                                        size="small"-->
        <!--                                        icon-pack="feather"-->
        <!--                                        icon="icon-trash"-->
        <!--                                        color="danger"-->
        <!--                                        class="mr-2"-->
        <!--                                ></vs-button>-->
        <!--                            </div>-->
        <!--                        </div>-->

        <!--                        <vs-list>-->
        <!--                            <vs-list-item-->
        <!--                                    icon-pack="feather"-->
        <!--                                    icon="icon-user"-->
        <!--                                    :title="advice.client_name"-->
        <!--                            ></vs-list-item>-->
        <!--                            <vs-list-item-->
        <!--                                    icon-pack="feather"-->
        <!--                                    icon="icon-mail"-->
        <!--                                    :title="advice.client_email"-->
        <!--                            ></vs-list-item>-->
        <!--                            <vs-list-item-->
        <!--                                    icon-pack="feather"-->
        <!--                                    icon="icon-phone"-->
        <!--                                    :title="advice.client_phone"-->
        <!--                            ></vs-list-item>-->
        <!--                            <vs-list-item-->
        <!--                                    icon-pack="feather"-->
        <!--                                    icon="icon-edit"-->
        <!--                                    title="Notes"-->
        <!--                                    :subtitle="advice.client_notes"-->
        <!--                            ></vs-list-item>-->
        <!--                        </vs-list>-->
        <!--                    </div>-->
        <!--                      </template>-->

        <!--                <template-->
        <!--                    slot="expand"-->
        <!--                        v-else-if="!selectMultiple"-->
        <!--                        class="expand-user"-->
        <!--                >-->
        <!--                    <div class="con-expand-users w-full">-->
        <!--                        <div-->
        <!--                                class="con-btns-user flex items-center justify-between"-->
        <!--                        >-->
        <!--                            <div-->
        <!--                                    class="con-userx flex items-center justify-start"-->
        <!--                            >-->
        <!--                                    <span-->
        <!--                                    >Advice request received from-->
        <!--                                        {{-->
        <!--                                        advice.user.details.full_name-->
        <!--                                        }}</span-->
        <!--                                    >-->
        <!--                            </div>-->
        <!--                            <div class="flex">-->
        <!--                                <div>-->
        <!--                                    <vs-button-->
        <!--                                            v-on:click="-->
        <!--                                                $emit('selectedEdit', advice)-->
        <!--                                            "-->
        <!--                                            type="flat"-->
        <!--                                            size="small"-->
        <!--                                            icon-pack="feather"-->
        <!--                                            icon="icon-edit-2"-->
        <!--                                            class="mr-2"-->
        <!--                                    ></vs-button>-->
        <!--                                </div>-->

        <!--                                <div>-->
        <!--                                    <vs-button-->
        <!--                                            v-on:click.stop="gotoUser(advice)"-->
        <!--                                            type="flat"-->
        <!--                                            size="small"-->
        <!--                                            icon-pack="feather"-->
        <!--                                            icon="icon-user"-->
        <!--                                            class="mr-2"-->
        <!--                                    ></vs-button>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                        </div>-->

        <!--                        <vs-list>-->
        <!--                            <vs-list-item-->
        <!--                                    icon-pack="feather"-->
        <!--                                    icon="icon-user"-->
        <!--                                    :title="advice.user.details.full_name"-->
        <!--                            ></vs-list-item>-->
        <!--                            <vs-list-item-->
        <!--                                    icon-pack="feather"-->
        <!--                                    icon="icon-mail"-->
        <!--                                    :title="-->
        <!--                                        advice.user.email +-->
        <!--                                        ' - Contact email :  ' +-->
        <!--                                        advice.user.details.contact_email-->
        <!--                                    "-->
        <!--                            ></vs-list-item>-->
        <!--                            <vs-list-item-->
        <!--                                    icon-pack="feather"-->
        <!--                                    icon="icon-phone"-->
        <!--                                    :title="advice.user.details.phone"-->
        <!--                            ></vs-list-item>-->
        <!--                            <vs-list-item-->
        <!--                                    icon-pack="feather"-->
        <!--                                    icon="icon-edit"-->
        <!--                                    title="Notes"-->
        <!--                                    :subtitle="advice.notes"-->
        <!--                            ></vs-list-item>-->
        <!--                        </vs-list>-->
        <!--                    </div>-->
        <!--                </template>-->
      </vs-tr>
      </tbody>
    </template>
  </vs-table>
</template>

<script>
import {textFormat} from '@/mixins/TextFormat'
import {tableHelpers} from '@/mixins/tableHelpers'

export default {
  name: 'AdviceTable',
  mixins: [textFormat, tableHelpers],
  props: ['data', 'itemsPerPage', 'dataSelected'],
  components: {},
  computed: {
    wills() {
      return this.data.data
    },
    selected: {
      set(value) {
        this.$emit('selected', value)
      },
      get() {
        return this.dataSelected
      },
    },
  },
  data() {
    return {
      searchQuery: '',
      status: 'All - Unresolved',
      selectMultiple: false,
    }
  },
  methods: {
    toggleSelect() {
      this.selected = []
      this.selectMultiple = !this.selectMultiple
    },
    statusLabel(advice) {
      if (advice.call_not_answered) return 'Call Not Answered'
      if (advice.to_follow_up) return 'To Follow Up'
      if (advice.end_lost) return 'End - Lost Not Taken'
      if (advice.end_no_contact) return 'End - Lost No Contact'
      if (advice.end_sold) return 'End - Sold'
      return 'To Contact'
    },
    gotoUser(event) {
      if (event.user)
        this.$router.push({
          name: 'user',
          params: {id: event.user.id},
        })
    },
    search(value) {
      if (value.length > 3 || value === '') {
        this.searchQuery = value
        this.$emit('search', value)
      }
    },
    setLimit(status, value) {
      this.status = status
      this.$emit('limit', value)
    },
    gotoUserInNewTab(advice) {
      var base =
          process.env.NODE_ENV === 'production'
              ? 'https://management.mylastwill.co.uk/user/'
              : 'http://127.0.0.1:8080/user/'
      var url =
          base + advice.user.id
      window.open(url, '_blank').focus();
    }
  },
}
</script>
