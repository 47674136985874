<template>
    <SideBarModal title="Advice" :show="show" @close="$emit('close')">
        <template v-slot:content>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <label for="" class="vs-input--label">Products</label>

                    <v-select
                        multiple
                        taggable
                        v-model="adviceData.selected_products"
                        :options="products"
                        placeholder="Select product or enter (type) your own"
                        class="w-full"
                    ></v-select>
                </div>
            </div>
            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <BrokerSelect @selectedBroker="setBroker"></BrokerSelect>
                </div>
            </div>

            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="adviceData.client_name"
                        label="Name"
                        class="w-full"
                        requried
                    />
                </div>
            </div>

            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="adviceData.client_email"
                        label="Email"
                        class="w-full"
                    />
                </div>
            </div>

            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="adviceData.client_phone"
                        label="Phone"
                        class="w-full"
                    />
                </div>
            </div>

            <div class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-textarea
                        v-model="adviceData.client_notes"
                        label="Notes"
                    />
                </div>
            </div>
            <!--
      <div class="flex mb-6">
        <div class="dropdown-button-container w-full mr-3">

          <vs-button
            class="btnx w-full"
            color="success"
            type="filled"
          >{{ statusLabel }}
          </vs-button>

          <vs-dropdown>

            <vs-button
              class="btn-drop"
              type="filled"
              color="success"
              icon="expand_more"
            ></vs-button>

            <vs-dropdown-menu class="w-1/5">

              <vs-dropdown-item @click="setToContact">
                To Contact
              </vs-dropdown-item>

              <vs-dropdown-item @click="setToFollowUp">
                To Follow Up
              </vs-dropdown-item>

              <vs-dropdown-item
                @click="setEndSold"
                divider
              >
                End - Sold
              </vs-dropdown-item>

              <vs-dropdown-item @click="setEndLost">
                End - Lost Not Taken
              </vs-dropdown-item>

              <vs-dropdown-item @click="setEndNoContact">
                End - Lost No Contact
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </div>
      -->
        </template>
        <template v-slot:buttons>
            <vs-button
                type="flat"
                color="success"
                class="mr-3"
                @click="adviceSave"
                >Create</vs-button
            >

            <vs-button type="flat" color="danger" @click="$emit('close')"
                >Cancel</vs-button
            >
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import BrokerSelect from '@/components/common/BrokerSelect'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'CreateAdviceModal',
    components: {
        SideBarModal,
        vSelect,
        BrokerSelect,
    },
    props: ['show'],
    data() {
        return {
            adviceData: {
                selected_products: null,
                client_name: null,
                client_phone: null,
                client_email: null,
                client_notes: null,
                broker: null,
                to_follow_up: false,
                call_not_answered: false,
                end_lost: false,
                end_no_contact: false,
                end_sold: false,
                finalised: false,
            },
            products: [
                { label: 'Complex Will', code: 'complex_will' },
                {
                    label: 'Lasting Power of Attorney',
                    code: 'power_of_attorney',
                },
                {
                    label: 'Protective Property Trust',
                    code: 'protective_property_trust',
                },
                { label: 'Home Protection Trust', code: 'protect_home' },
                { label: 'Right of Occupation', code: 'right_of_occupation' },
                { label: 'Life Interest Trust', code: 'life_interest_trust' },
            ],
        }
    },
    computed: {
        statusLabel() {
            if (this.adviceData.call_not_answered) return 'Call Not Answered'
            if (this.adviceData.to_follow_up) return 'To Follow Up'
            if (this.adviceData.end_lost) return 'End - Lost Not Taken'
            if (this.adviceData.end_no_contact) return 'End - Lost No Contact'
            if (this.adviceData.end_sold) return 'End - Sold'
            return 'To Contact'
        },
    },
    methods: {
        setCreated() {
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = false
            this.adviceData.finalised = false
        },
        setToContact() {
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = false
            this.adviceData.finalised = false
        },
        setToFollowUp() {
            this.adviceData.to_follow_up = true
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = false
            this.adviceData.finalised = false
        },
        setEndLost() {
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = true
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = false
            this.adviceData.finalised = true
        },
        setEndNoContact() {
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = true
            this.adviceData.end_sold = false
            this.adviceData.finalised = true
        },
        setEndSold() {
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = true
            this.adviceData.finalised = true
        },
        setBroker(value) {
            if (value) this.adviceData.broker = value.value
            else this.adviceData.broker = null
        },
        adviceSave() {
            http.post('advice_detail', this.adviceData)
                .then((response) => {
                    this.$emit('close')
                    this.$emit('changed')
                    this.$vs.notify({
                        text: 'Saved',
                        color: 'success',
                    })
                })
                .catch((error) => {
                    console.log(error)
                    this.$vs.notify({
                        text: error.response.data,
                        color: 'success',
                    })
                })
        },
    },
}
</script>

<style lang="scss">
.dropdown-button-container {
    display: flex;
    align-items: center;

    .btnx {
        border-radius: 5px 0px 0px 5px;
    }

    .btn-drop {
        border-radius: 0px 5px 5px 0px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
}
.con-vs-dropdown--menu {
    z-index: 70000;
}
</style>
