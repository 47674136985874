<template>
    <SideBarModal title="Advice" :show="show" @close="$emit('close')">
        <template v-slot:content>
            <div v-if="adviceData.broker" class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="adviceData.client_name"
                        label="Name"
                        class="w-full"
                    />
                </div>
            </div>

            <div v-if="adviceData.broker" class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="adviceData.client_email"
                        label="Email"
                        class="w-full"
                    />
                </div>
            </div>

            <div v-if="adviceData.broker" class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="adviceData.client_phone"
                        label="Phone"
                        class="w-full"
                    />
                </div>
            </div>

            <div v-if="adviceData.broker" class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-textarea
                        v-model="adviceData.client_notes"
                        label="Notes"
                        rows="10"
                    />
                </div>
            </div>

            <div v-if="!adviceData.broker" class="vx-row mb-2">
                <div class="vx-col w-full">
                    <vs-textarea
                        v-model="adviceData.notes"
                        label="Notes"
                        rows="10"
                    />
                </div>
            </div>

            <div class="flex mb-6">
                <div class="dropdown-button-container w-full mr-3">
                    <vs-button
                        class="btnx w-full"
                        color="success"
                        type="filled"
                        >{{ statusLabel }}</vs-button
                    >

                    <vs-dropdown>
                        <vs-button
                            class="btn-drop"
                            type="filled"
                            color="success"
                            icon="expand_more"
                        ></vs-button>

                        <vs-dropdown-menu class="w-1/5">
                            <vs-dropdown-item @click="setToContact">
                                To Contact
                            </vs-dropdown-item>

                            <vs-dropdown-item @click="setCallNotAnswered">
                                Call Not Answered
                            </vs-dropdown-item>

                            <vs-dropdown-item @click="setToFollowUp">
                                To Follow Up
                            </vs-dropdown-item>

                            <vs-dropdown-item @click="setEndSold" divider>
                                End - Sold
                            </vs-dropdown-item>

                            <vs-dropdown-item @click="setEndLost">
                                End - Lost Not Taken
                            </vs-dropdown-item>

                            <vs-dropdown-item @click="setEndNoContact">
                                End - Lost No Contact
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
            </div>
        </template>
        <template v-slot:buttons>
            <vs-button
                type="flat"
                color="success"
                class="mr-3"
                @click="adviceSave"
                >Save</vs-button
            >

            <vs-button type="flat" color="danger" @click="$emit('close')"
                >Cancel</vs-button
            >
        </template>
    </SideBarModal>
</template>

<script>
import { http } from '@/services'

import SideBarModal from '@/components/common/SideBarModal'

export default {
    name: 'EditAdviceModal',
    components: {
        SideBarModal,
    },
    props: ['data', 'show'],
    watch: {
        data: {
            deep: true,
            immediate: true,
            handler(data) {
                this.adviceData = { ...data }
            },
        },
    },
    data() {
        return {
            adviceData: null,
        }
    },
    computed: {
        statusLabel() {
            if (this.adviceData.call_not_answered) return 'Call Not Answered'
            if (this.adviceData.to_follow_up) return 'To Follow Up'
            if (this.adviceData.end_lost) return 'End - Lost Not Taken'
            if (this.adviceData.end_no_contact) return 'End - Lost No Contact'
            if (this.adviceData.end_sold) return 'End - Sold'
            return 'To Contact'
        },
    },
    methods: {
        setCreated() {
            this.adviceData.call_not_answered = false
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = false
            this.adviceData.finalised = false
        },
        setToContact() {
            this.adviceData.call_not_answered = false
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = false
            this.adviceData.finalised = false
        },
        setToFollowUp() {
            this.adviceData.call_not_answered = false
            this.adviceData.to_follow_up = true
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = false
            this.adviceData.finalised = false
        },
        setEndLost() {
            this.adviceData.call_not_answered = false
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = true
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = false
            this.adviceData.finalised = true
        },
        setEndNoContact() {
            this.adviceData.call_not_answered = false
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = true
            this.adviceData.end_sold = false
            this.adviceData.finalised = true
        },
        setEndSold() {
            this.adviceData.call_not_answered = false
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = true
            this.adviceData.finalised = true
        },
        setCallNotAnswered() {
            this.adviceData.call_not_answered = true
            this.adviceData.to_follow_up = false
            this.adviceData.end_lost = false
            this.adviceData.end_no_contact = false
            this.adviceData.end_sold = false
            this.adviceData.finalised = false
        },
        adviceSave() {
            if (this.adviceData && this.adviceData.id) {
                var formData = new FormData()
                formData.append('id', this.adviceData.id)
                formData.append('client_notes', this.adviceData.client_notes)
                formData.append('notes', this.adviceData.notes)
                if (this.adviceData.client_name)
                    formData.append('client_name', this.adviceData.client_name)
                if (this.adviceData.client_phone)
                    formData.append(
                        'client_phone',
                        this.adviceData.client_phone
                    )
                if (this.adviceData.client_email)
                    formData.append(
                        'client_email',
                        this.adviceData.client_email
                    )
                formData.append('finalised', this.adviceData.finalised)
                formData.append('call_not_answered', this.adviceData.call_not_answered)
                formData.append('to_follow_up', this.adviceData.to_follow_up)
                formData.append('end_lost', this.adviceData.end_lost)
                formData.append(
                    'end_no_contact',
                    this.adviceData.end_no_contact
                )
                formData.append('end_sold', this.adviceData.end_sold)

                http.patch('advice_detail', formData)
                    .then((response) => {
                        this.$emit('newData', response.data)
                        this.$emit('close')
                        this.$emit('changed')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        },
    },
}
</script>

<style lang="scss">
.dropdown-button-container {
    display: flex;
    align-items: center;

    .btnx {
        border-radius: 5px 0px 0px 5px;
    }

    .btn-drop {
        border-radius: 0px 5px 5px 0px;
        border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
}
.con-vs-dropdown--menu {
    z-index: 70000;
}
</style>
